import { ColumnSettings } from 'handsontable/settings';
import {
  NUMERIC_FORMAT_PATTERN,
  parseDateToDefaultFormatWithRenderer,
} from '../common/utils';
import { CellValue } from 'exceljs';

export const fxMaturedDealsReportColumnConfig = (
  sheet: CellValue[][],
): ColumnSettings[] => [
  {
    width: 160,
    readOnly: true,
    renderer: parseDateToDefaultFormatWithRenderer,
    readOnlyCellClassName: 'text-black',
  },
  {
    width: 100,
    readOnly: true,
    readOnlyCellClassName: 'text-black',
    renderer: parseDateToDefaultFormatWithRenderer,
  },
  {
    width: 75,
    readOnly: true,
    readOnlyCellClassName: 'text-black',
    renderer: parseDateToDefaultFormatWithRenderer,
  },
  {
    width: 125,
    readOnly: true,
    readOnlyCellClassName: 'text-black',
    renderer: parseDateToDefaultFormatWithRenderer,
  },
  {
    width: 150,
    type: 'numeric',
    numericFormat: { pattern: NUMERIC_FORMAT_PATTERN },
    readOnly: true,
    readOnlyCellClassName: 'text-black',
  },
  {
    width: 150,
    type: 'numeric',
    numericFormat: { pattern: NUMERIC_FORMAT_PATTERN },
    readOnly: true,
    readOnlyCellClassName: 'text-black',
  },
  {
    width: 100,
    readOnly: true,
    readOnlyCellClassName: 'text-black',
    ...(sheet[0].length === 12 && {
      type: 'numeric',
      numericFormat: { pattern: NUMERIC_FORMAT_PATTERN },
    }),
  },
  {
    width: 100,
    readOnly: true,
    readOnlyCellClassName: 'text-black',
    ...(sheet[0].length === 12 && {
      type: 'numeric',
      numericFormat: { pattern: NUMERIC_FORMAT_PATTERN },
    }),
  },
  {
    width: 100,
    readOnly: true,
    readOnlyCellClassName: 'text-black',
  },
  {
    width: 150,
    readOnly: true,
    readOnlyCellClassName: 'text-black',
    type: 'numeric',
    numericFormat: { pattern: NUMERIC_FORMAT_PATTERN },
  },
  {
    width: 100,
    readOnly: true,
    readOnlyCellClassName: 'text-black',
    ...(sheet[0].length === 12 && {
      type: 'numeric',
      numericFormat: { pattern: NUMERIC_FORMAT_PATTERN },
    }),
  },
  {
    width: 175,
    readOnly: true,
    readOnlyCellClassName: 'text-black',
    type: 'numeric',
    numericFormat: { pattern: NUMERIC_FORMAT_PATTERN },
    wordWrap: true,
  },
  {
    width: 150,
    readOnly: true,
    readOnlyCellClassName: 'text-black',
    wordWrap: true,
  },
];
