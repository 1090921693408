import axios from 'axios';

export const Axios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export function axiosHasAuthToken(): boolean {
  return !!Axios.defaults.headers.common['Authorization'];
}

export const isAxiosError = axios.isAxiosError;
