import {
  loginWithCredentials,
  refreshUserToken,
  signOut,
} from '../cognito/cognito';
import { Axios, isAxiosError } from '../axios/Axios';
import { IUser } from './interfaces/IUser';

export const getUserData: () => Promise<IUser> = async () => {
  const response = await Axios.get('/auth/info');
  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.statusText);
};

export const login = async (
  email: string,
  password: string,
): Promise<IUser> => {
  const cognitoSession = await loginWithCredentials(email, password);
  Axios.defaults.headers.common['Authorization'] = `Bearer ${cognitoSession
    .getIdToken()
    .getJwtToken()}`;
  return await getUserData();
};

export const signup = async (
  name: string,
  lastName: string,
  email: string,
  password: string,
): Promise<IUser> => {
  try {
    const response = await Axios.post('/auth/signup', {
      name,
      lastName,
      email,
      password,
    });
    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw new Error(error.response?.data.message || error.message);
    }

    throw new Error(error as string);
  }
};

export const logout = async () => {
  Axios.defaults.headers.common['Authorization'] = '';
  await signOut();
};

export const deleteUser = async (user: IUser) => {
  await Axios.delete(`/user/${user.id}`);
};

export const refreshSession = async () => {
  try {
    const cognitoSession = await refreshUserToken();
    Axios.defaults.headers.common['Authorization'] = `Bearer ${cognitoSession
      .getIdToken()
      .getJwtToken()}`;
  } catch (e) {
    console.log(e);
  }
};
