import moment from 'moment';
import { Link } from 'react-router-dom';
import { useUser } from '../../../hooks/useUser';
import { UserRole } from '../../../services/hedgehog/enum/UserRole.enum';
import { IHedgeEffectivenessReport } from '../../../services/hedgehog/interfaces/IHedgeEffectivenessReport';
import { IIrsMtmJournal } from '../../../services/hedgehog/interfaces/IIrsMtmJournals';
import { IOrganization } from '../../../services/hedgehog/interfaces/IOrganization';
import { IReport } from '../../../services/hedgehog/interfaces/IReportByMonth';
import { OutputReportTypes } from '../../../services/hedgehog/types/OutputReportTypes';
import { useReportActions } from './useReportActions';

interface Filters {
  month: string;
  year: string;
  status: string | undefined;
  customFilters: Set<OutputReportTypes>;
  organizationId: number;
}

interface IReportMonthlyTableProps {
  reports: IReport[];
  organization: IOrganization;
  filters: Filters;
}

const columnNames = ['name', 'type - reference', 'reviewed', 'actions'];

export const ReportMonthlyTable = ({
  reports,
  organization,
  filters,
}: IReportMonthlyTableProps) => {
  const { user } = useUser();

  const {
    handleDownloadFileByType,
    handleViewReport,
    handleDeleteReport,
    handleReview,
    handleViewInputsModal,
  } = useReportActions({
    filters,
    organizationName: organization.name,
    userRole: user?.role || '',
  });

  return (
    <table className="w-full text-sm text-left text-gray-700">
      <thead className="text-xs text-gray-800 uppercase ">
        <tr className="bg-white whitespace-nowrap border-b hover:bg-primary-cyan-100 ease-in transition duration-100">
          {columnNames.map((key) => (
            <th key={key} className="px-4 py-4 space-x-2">
              {key}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {reports.map((report) => {
          if (user?.role === UserRole.User && !report.reviewDate) return null;
          return (
            <tr
              key={`${report.id}-${report.type}`}
              data-cy={`${report.id}-${report.type}`}
              className="bg-white border-b hover:bg-primary-cyan-100 ease-in transition duration-100"
            >
              <td className="px-4 min-w-[175px] py-4 space-x-2">
                {report.name}
              </td>

              <td className="px-4 min-w-[175px] py-4">
                <div>
                  <span>{report.type}</span>
                </div>
                <div className="text-gray-500 capitalize ">
                  {report.type === OutputReportTypes.IRS_MTM_JOURNAL ||
                  report.type ===
                    OutputReportTypes.IRS_HEDGE_EFFECTIVENESS_REPORT ? (
                    <span className="text-sm">
                      {
                        (report as IHedgeEffectivenessReport | IIrsMtmJournal)
                          .reference
                      }
                    </span>
                  ) : (
                    <span className="text-sm"></span>
                  )}
                </div>
              </td>
              <td className="px-4 whitespace-nowrap py-4 space-x-2">
                {report?.reviewDate ? (
                  moment(new Date(report.reviewDate)).format('DD-MMM-YY')
                ) : (
                  <button
                    className="font-medium text-blue-600 hover:underline"
                    data-cy={`review-${handleViewReport(report.type)}-${
                      report.id
                    }`}
                    onClick={() => handleReview(report)}
                  >
                    Review
                  </button>
                )}
              </td>
              <td className="px-4 min-w-[200px] py-4">
                <div className="flex gap-2 flex-wrap">
                  {report.type === OutputReportTypes.RAW_REPORT ? null : (
                    <Link
                      to={`/${user?.role}/view/${handleViewReport(
                        report.type,
                      )}/${report.id}`}
                      className="font-medium text-blue-600 hover:underline"
                      data-cy={`view-${handleViewReport(report.type)}-${
                        report.id
                      }`}
                      state={{ showBackButton: true }}
                    >
                      View
                    </Link>
                  )}
                  <button
                    onClick={() => {
                      handleDownloadFileByType(
                        report.type,
                        report.id,
                        report.name,
                      );
                    }}
                    className="font-medium text-blue-600 hover:underline"
                    data-cy={`download-${handleViewReport(report.type)}-${
                      report.id
                    }`}
                  >
                    Download
                  </button>
                  {user?.role === UserRole.Admin && (
                    <>
                      {report.type !==
                        OutputReportTypes.IRS_HEDGE_EFFECTIVENESS_REPORT && (
                        <button
                          className="font-medium text-blue-600 hover:underline"
                          onClick={() =>
                            handleViewInputsModal(report.id, report.type)
                          }
                          data-cy={`open-inputs-${handleViewReport(
                            report.type,
                          )}-${report.id}`}
                        >
                          View Inputs
                        </button>
                      )}
                      <button
                        className="font-medium text-red-600 hover:underline"
                        data-cy={`delete-${handleViewReport(report.type)}-${
                          report.id
                        }`}
                        onClick={() => handleDeleteReport(report)}
                      >
                        Delete
                      </button>
                    </>
                  )}
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
