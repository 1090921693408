import { getCellProperties } from '../../common/utils';

export const getIrsJournalCellProperties = (
  sheet: any[][],
  headerPatterns: RegExp[],
  row: number,
  col: number,
) => {
  const cellProperties = getCellProperties(sheet, headerPatterns, row, col);

  const cellValue = sheet[row][col] ? String(sheet[row][col]) : '';

  const titles = ['Post as a Reserving Journal', 'Post as a Reversing Journal'];
  const isTitleHeader = titles.some((title: string) => title === cellValue);

  if (isTitleHeader) {
    cellProperties.className += ' red-title';
  }

  return cellProperties;
};
