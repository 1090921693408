import { useQuery } from 'react-query';
import { ApiQueryKeys } from '../../../services/hedgehog/api-query-keys.enum';
import { getInputsFromOutput } from '../../../services/hedgehog/hedgehog.api';
import { SimpleLoadingModal } from '../SimpleLoadingModal';
import { Modal } from '../Modal';
import moment from 'moment';
import { handleDownloadFile } from '../../../utils/handleDownloadFile';
import { OutputGetFileByIdRoutes } from '../../../services/hedgehog/types/OutputReportRoutes';
import { SimpleAlert } from '../../alerts/SimpleAlert';

interface IViewInputsModalProps {
  outputdId: number;
  isIrsJournal: boolean;
  organizationName: string;
  close: () => void;
}

const columnNames = ['Id', 'Input Type', 'Date', 'Actions'];

export const ViewInputsModal = ({
  outputdId,
  isIrsJournal,
  organizationName,
  close,
}: IViewInputsModalProps) => {
  const {
    data: inputs,
    isLoading,
    error,
  } = useQuery([ApiQueryKeys.inputs, outputdId], () =>
    getInputsFromOutput(outputdId, isIrsJournal),
  );

  const handleClickDownlaod = (id: number, name: string) => {
    handleDownloadFile(
      name,
      organizationName,
      id,
      OutputGetFileByIdRoutes['Input Report'],
    );
  };

  if (isLoading) {
    return <SimpleLoadingModal />;
  }

  if (error) {
    return (
      <SimpleAlert
        alertMessage={'Erorr while fetching inputs'}
        alertType="danger"
      />
    );
  }

  return (
    <>
      <Modal close={close} closeOnOutClick>
        <div className="w-3/6 flex flex-col items-center bg-white py-4">
          <h3 className="text-base font-semibold mb-1">Inputs Report</h3>
          <table className="w-fit h-fit bg-white border border-gray-200 shadow-md rounded-lg mb-1 text-sm text-left text-gray-700 ">
            <thead className="text-xs text-gray-800 uppercase ">
              <tr className="bg-white border-b hover:bg-primary-cyan-100 ease-in transition duration-100">
                {columnNames.map((name) => (
                  <th key={name} className="px-4 py-4 space-x-2">
                    {name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {inputs?.map((input) => (
                <tr
                  key={input.id}
                  className="bg-white border-b hover:bg-primary-cyan-100 ease-in transition duration-100"
                >
                  <td className="px-4 py-4 space-x-2">{input.id}</td>
                  <td className="px-4 py-4 space-x-2">{input.inputType}</td>
                  <td className="px-4 py-4 space-x-2">
                    {moment(input.reportingDate).format('MMM YY')}
                  </td>

                  <td className="px-4 py-4">
                    <button
                      className="font-medium text-blue-600 hover:underline"
                      data-cy={`download-input-report${input.id}`}
                      onClick={() =>
                        handleClickDownlaod(+input.id, input.inputType)
                      }
                    >
                      Download
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal>
    </>
  );
};
