import { Axios } from '../axios/Axios';
import { IOrganization } from './interfaces/IOrganization';
import { IInputReport } from './interfaces/IInputReport';
import { IUser, UserAndCount } from './interfaces/IUser';
import {
  GeneratedOutputReportTypes,
  OutputReportRoutes,
  OutputReportTypes,
} from './types/OutputReportTypes';
import { IOutputReport } from './interfaces/IOutputReport';
import { IPeople, Position } from './interfaces/IPeople';
import { AxiosError, AxiosResponse } from 'axios';
import { UNEXPECTED_ERROR_MESSAGE } from '../../error/errorMessages.constants';
import { IIrsHedgeDocumentationForm } from './interfaces/IHedgeDocumentationForm';
import { HedgeDocumentationFormOption } from './enum/HedgeDocumentationFormOption.enum';
import { IIrsMtmJournal } from './interfaces/IIrsMtmJournals';
import { IReportingPeriods } from './interfaces/IReportingPeriods';
import { IReport, IReportByMonth } from './interfaces/IReportByMonth';
import { IOrganizationProfile } from './interfaces/IOrganizationProfile';
import { ExposureType } from './enum/ExposureType.enum';
import { IOrganizationUpdate } from '../../components/modal/update-organization-modal/interface/IOrganizationUpdate';
import { IIRSTrade, IIRSTradeAndCount } from './interfaces/IIRSTrade';
import { IFxTrade } from './interfaces/IFXTrade';
import { IReportingPeriodsQuery } from '../../components/common/reporting-periods/interface/IReportingPeriodsQuery';
import { IOrganizationsAndCount } from './interfaces/IOrganizationsAndCount';
import { IOrganizationQuery } from './interfaces/IOrganizationQuery';
import { IGetOrganizationIrsTradesQuery } from './interfaces/IGetOrganizationIrsTradesQuery';
import { IPagination } from './interfaces/IPagination';
import { IInputReportSummary } from './interfaces/IInputReportSummary';
import JSZip, { JSZipObject } from 'jszip';
import { IAddExistingMemberParameters } from './types/add-existing-member-parameters';
import { RawReportTypes } from '../../components/modal/add-report-modal/RawReportTypes.enum';
import { isRawHedgeDocumentation } from '../../components/modal/add-report-modal/utils';
import { IReportsToNotify } from './interfaces/IReportsToNotify';

export const getOrganizations = async (
  query?: IOrganizationQuery,
): Promise<IOrganizationsAndCount> => {
  const response = await Axios.get('/organization', { params: query });
  return response.data;
};

export const createOrganization = async (
  newOrganization: {
    name: string;
    exposureType: ExposureType;
    organizationProfile: IOrganizationProfile;
  },
  manager: IUser,
) => {
  const payload = { ...newOrganization, manager: manager };
  const reponse = await Axios.post('/organization', payload);

  return reponse.data;
};

export const uploadImages = async (
  file: File,
): Promise<{
  location: string;
  key: string;
}> => {
  const formData = new FormData();

  formData.append('img', file);

  try {
    const response = await Axios.post('/aws/upload-img', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(e.response?.data.message);
    }
    throw new Error('Unexpected error occurred');
  }
};
export const getReportingPeriodsByOrganization = async (
  organizationId: number,
  queryParam?: IReportingPeriodsQuery,
): Promise<IReportingPeriods[]> => {
  try {
    const response = await Axios.get(
      `/organization/reporting-periods/${organizationId}`,
      {
        params: queryParam,
      },
    );
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError && error.response?.data.message)
      throw new Error(error.response?.data.message);
    else if (error instanceof AxiosError) throw new Error(error.message);

    throw new Error(UNEXPECTED_ERROR_MESSAGE);
  }
};

export const getReportByMonthAndYear = async (
  organizationId: number,
  query: {
    month?: string;
    year?: string;
    reportStatus?: string;
    types: OutputReportTypes[];
  },
): Promise<IReportByMonth> => {
  const { month, year, reportStatus, types } = query;

  const yearInNumber = Number(year);

  try {
    const organization = await getOrganizationById(organizationId);
    const response = await Axios.get(
      `/organization/report-by-month/${organizationId}`,
      {
        params: {
          month,
          year: yearInNumber,
          reportStatus,
          types: types.join(','),
        },
      },
    );

    return { ...response.data, organization };
  } catch (error) {
    if (error instanceof AxiosError && error.response?.data.message)
      throw new Error(error.response?.data.message);
    else if (error instanceof AxiosError) throw new Error(error.message);

    throw new Error(UNEXPECTED_ERROR_MESSAGE);
  }
};

export const getAllReportByOrganization = async (
  organizationId: number,
): Promise<IReportByMonth> => {
  try {
    const response = await Axios.get(`/organization/reports/${organizationId}`);

    return response.data;
  } catch (error) {
    if (error instanceof AxiosError && error.response?.data.message)
      throw new Error(error.response?.data.message);
    else if (error instanceof AxiosError) throw new Error(error.message);

    throw new Error(UNEXPECTED_ERROR_MESSAGE);
  }
};

export const getOrganizationMembers = async (
  organizationId: number,
): Promise<IPeople[]> => {
  const response = await Axios.get(`/organization/${organizationId}/people`);
  return response.data;
};

export const getReportsToNotify = async (
  organizationId: number,
): Promise<IReportsToNotify> => {
  const response = await Axios.get(
    `/organization/${organizationId}/reviews/pending-notification`,
  );
  return response.data;
};

export const notifyReports = async (organizationId: number): Promise<void> => {
  await Axios.post(`/organization/${organizationId}/reviews/notify`);
};

export const addOrganizationMember = async (
  user: IUser,
  role: Position,
  organizationId: number,
): Promise<IPeople> => {
  const response = await Axios.post(`/organization/${organizationId}/people`, {
    user,
    position: role,
  });
  return response.data;
};

export const addExistingMemberToOrganization = async (
  values: IAddExistingMemberParameters,
) => {
  const member = await getUserByEmail(values.email);
  await addOrganizationMember(member, values.role, values.organizationId);
};

export const archiveOrganization = async (
  organizationId: number,
): Promise<void> => {
  await Axios.post(`/organization/${organizationId}/archive`);
};

export const unarchiveOrganization = async (
  organizationId: number,
): Promise<void> => {
  await Axios.post(`/organization/${organizationId}/unarchive`);
};

export const deleteOrganizationMember = async (
  userId: string,
  organizationId: number,
): Promise<void> => {
  await Axios.delete(`/organization/${organizationId}/user/${userId}`);
};

export const deleteOrganization = async (id: number) => {
  try {
    const response = await Axios.delete(`/organization/${id}`);
    return response.data;
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(e.response?.data.message);
    }
    throw new Error(UNEXPECTED_ERROR_MESSAGE);
  }
};

export const getOrganizationById = async (
  id: number,
): Promise<IOrganization> => {
  try {
    const response = await Axios.get<IOrganization>(`/organization/${id}`);
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError)
      throw new Error(error.response?.data.message);
    else throw new Error(UNEXPECTED_ERROR_MESSAGE);
  }
};

interface ZipFiles {
  [key: string]: JSZipObject;
}

type IJournalsFiles = { name: string; data: Blob };

export const getFxJournalsFromOrganization = async (
  id: number,
): Promise<IJournalsFiles[]> => {
  try {
    const response: AxiosResponse<Blob> = await Axios.get(
      `/organization/${id}/fx-journal-files`,
      {
        responseType: 'blob',
      },
    );
    const blob = response.data;
    const zip = new JSZip();
    await zip.loadAsync(blob);
    const files: ZipFiles = zip.files;
    const data: IJournalsFiles[] = [];

    const filePromises = Object.keys(files).map(async (fileName: string) => {
      const file = files[fileName];
      const fileBlob: Blob = await file.async('blob');

      const blobWithType = new Blob([fileBlob], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      data.push({
        name: fileName,
        data: blobWithType,
      });
    });

    await Promise.all(filePromises);

    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new Error(error.response?.data.message);
    } else {
      throw new Error('Unexpected error occurred');
    }
  }
};

export const updateOrganizationProfile = async (
  organizationId: number,
  organizationProfile: IOrganizationProfile,
): Promise<IOrganizationProfile> => {
  try {
    const response = await Axios.put(
      `/organization/${organizationId}/profile`,
      organizationProfile,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError)
      throw new Error(error.response?.data.message);
    else throw new Error(UNEXPECTED_ERROR_MESSAGE);
  }
};

export const updateOrganization = async (
  organizationId: number,
  organization: IOrganizationUpdate,
): Promise<IOrganization> => {
  try {
    const response = await Axios.put(
      `/organization/${organizationId}`,
      organization,
    );
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError)
      throw new Error(error.response?.data.message);
    else throw new Error(UNEXPECTED_ERROR_MESSAGE);
  }
};

export const updateOrganizationLogo = async (
  organizationId: number,
  file: File,
) => {
  const formData = new FormData();

  formData.append('img', file);

  try {
    const response = await Axios.put(
      `organization/update-logo/${organizationId}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError)
      throw new Error(error.response?.data.message);
    else throw new Error(UNEXPECTED_ERROR_MESSAGE);
  }
};

export const getOrganizationLogoById = async (organizationId: number) => {
  try {
    const response = await Axios.get(`organization/logo/${organizationId}`, {
      responseType: 'arraybuffer',
    });
    const blob = new Blob([response.data], {
      type: response.headers['content-type'],
    });
    return URL.createObjectURL(blob);
  } catch (error) {
    if (error instanceof AxiosError && error.response?.data.message)
      throw new Error(error.response?.data.message);
    else if (error instanceof AxiosError) throw new Error(error.message);

    throw new Error(UNEXPECTED_ERROR_MESSAGE);
  }
};

export const getOrganizationReports = async (
  organizationId: number,
): Promise<IOutputReport[]> => {
  const response = await Axios.get(
    `/organization/${organizationId}/output-report`,
  );
  return response.data;
};
export const getOrganizationInputReports = async (
  organizationId: number,
  inputType?: string,
  pagination?: {
    limit: number;
    offset: number;
  },
): Promise<IInputReport[]> => {
  const response = await Axios.get(
    `/organization/${organizationId}/input-report`,
    { params: { inputType, ...pagination } },
  );
  return response.data;
};

export const getOrganizationHedgeDocumentationForm = async (
  organizationId: number,
): Promise<IIrsHedgeDocumentationForm[]> => {
  const response = await Axios.get(
    `/organization/${organizationId}/hedge-documentation-form`,
  );
  return response.data;
};

export const getOrganizationIrsMtmJournal = async (
  organizationId: number,
): Promise<IIrsMtmJournal[]> => {
  const response = await Axios.get(
    `/organization/${organizationId}/irs-mtm-journal`,
  );
  return response.data;
};

export const getReports = async (query: {
  limit: number;
  offset: number;
}): Promise<IInputReport[]> => {
  const response = await Axios.get('/report', { params: query });
  return response.data;
};

export const generateOutputReport = async (
  organizationId: number,
  outputType: GeneratedOutputReportTypes,
  inputReports: IInputReport[],
  staticData: { [key: string]: any },
) => {
  if (outputType === OutputReportTypes.IRS_HEDGE_DOCUMENTATION_FORM) {
    return generateHedgeDocumentationForm(
      staticData.hedgeDocumentationFormId,
      inputReports,
      staticData,
    );
  } else {
    return generateOutputReportDefault(
      organizationId,
      outputType,
      inputReports,
      staticData,
    );
  }
};

export const generateHedgeDocumentationForm = async (
  hedgeDocumentationFormId: number,
  inputReports: IInputReport[],
  staticData: { [key: string]: any },
) => {
  const payload = {
    inputs: inputReports,
    ...staticData,
  };

  try {
    const response = await Axios.put(
      `/hedge-documentation-form/${hedgeDocumentationFormId}`,
      payload,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    return response.data;
  } catch (e: unknown) {
    if (e instanceof AxiosError && e.response?.data.message)
      throw new Error(e.response?.data.message);
    else if (e instanceof AxiosError) throw new Error(e.message);

    throw new Error(UNEXPECTED_ERROR_MESSAGE);
  }
};

export const generateOutputReportDefault = async (
  organizationId: number,
  outputType: GeneratedOutputReportTypes,
  inputReports: IInputReport[],
  staticData: { [key: string]: any },
) => {
  const payload = {
    organizationId: +organizationId,
    type: outputType,
    inputs: inputReports,
    ...staticData,
  };

  try {
    const response = await Axios.post(
      `/output-report/${OutputReportRoutes[outputType]}`,
      payload,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    return response.data;
  } catch (e: unknown) {
    if (e instanceof AxiosError && e.response?.data.message)
      throw new Error(e.response?.data.message);
    else if (e instanceof AxiosError) throw new Error(e.message);

    throw new Error(UNEXPECTED_ERROR_MESSAGE);
  }
};

export const getUsers = async (query: IPagination): Promise<UserAndCount> => {
  const response = await Axios.get('/user', { params: query });
  return response.data;
};

export const updateUser = async (
  userId: string,
  partialUser: Partial<IUser>,
) => {
  const response = await Axios.put(`/user/${userId}`, partialUser);
  return response.data;
};

export const getUserByEmail = async (email: string): Promise<IUser> => {
  const response = await Axios.get('/user', { params: { email } });
  return response.data;
};

export const getCompleteOrIncompleteHedgeDocumentationForm = async (
  option:
    | HedgeDocumentationFormOption.Complete
    | HedgeDocumentationFormOption.Incomplete,
): Promise<IIrsHedgeDocumentationForm[]> => {
  const response = await Axios.get(`/hedge-documentation-form/${option}/all`);
  return response.data;
};

export const getReportById = async (
  reportUrl: string,
  id: number,
): Promise<IReport> => {
  try {
    const response = await Axios.get(`/${reportUrl}/${id}`);
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError)
      throw new Error(error.response?.data.message);
    else throw new Error(UNEXPECTED_ERROR_MESSAGE);
  }
};

export const getReportFileById = async (reportUrl: string, id: number) => {
  try {
    const response = await Axios.get(`/${reportUrl}/file/${id}`, {
      responseType: 'blob',
    });
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      const errorBlob = error?.response?.data;

      const errorText = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.readAsText(errorBlob);
      });

      const errorData = JSON.parse(errorText as string);

      throw new Error(errorData.message);
    } else throw new Error(UNEXPECTED_ERROR_MESSAGE);
  }
};

type UploadReportsPayload = {
  organizationId: number;
  inputType: string;
  report: File;
};
export const uploadInputReport = async (
  payload: UploadReportsPayload,
): Promise<IInputReport> => {
  const formData = new FormData();

  formData.append('organizationId', payload.organizationId.toString());
  formData.append('inputType', payload.inputType);
  formData.append('report', payload.report);

  try {
    const response = await Axios.post('/input-report/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(e.response?.data.message);
    }
    throw new Error(UNEXPECTED_ERROR_MESSAGE);
  }
};

export const uploadRawReport = async (payload: {
  organizationId: number;
  name: string;
  reportingDate: Date;
  report: File;
  rawReportType: RawReportTypes;
  reference?: string;
}): Promise<void> => {
  const formData = new FormData();

  formData.append('organizationId', payload.organizationId.toString());
  formData.append('name', payload.name);
  formData.append('type', OutputReportTypes.RAW_REPORT);
  formData.append('reportingDate', payload.reportingDate.toISOString());
  formData.append('report', payload.report);

  if (isRawHedgeDocumentation(payload.rawReportType) && payload.reference) {
    formData.append('reference', payload.reference);
  }

  switch (payload.rawReportType) {
    case RawReportTypes.OUTPUT_REPORT:
      await uploadRawOutputReport(formData);
      break;
    case RawReportTypes.IRS_HEDGE_DOCUMENTATION:
      await uploadRawIrsHedgeDocumentationReport(formData);
      break;
    case RawReportTypes.FX_HEDGE_DOCUMENTATION:
      await uploadRawFxHedgeDocumentationReport(formData);
      break;
  }
};

const uploadRawOutputReport = async (formData: FormData) => {
  const response = await Axios.post('/output-report/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

const uploadRawIrsHedgeDocumentationReport = async (formData: FormData) => {
  const response = await Axios.post('/hedge-documentation-form', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

const uploadRawFxHedgeDocumentationReport = async (formData: FormData) => {
  const response = await Axios.post('/fx-hedge-documentation-form', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

export const migrateHedgeEffectivenessReport = async (
  organizationId: number,
  file: File,
): Promise<void> => {
  const formData = new FormData();
  formData.append('organizationId', organizationId.toString());
  formData.append('report', file);
  try {
    const response = await Axios.post(
      '/hedge-effectiveness-report/migrate',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return response.data;
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(e.response?.data.message);
    }
    throw new Error(UNEXPECTED_ERROR_MESSAGE);
  }
};

export const deleteReport = async (report: IReport) => {
  try {
    const response = await Axios.delete(`/output-report/${report.id}`, {
      data: { type: report.type },
    });
    return response.data;
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(e.response?.data.message);
    }
    throw new Error(UNEXPECTED_ERROR_MESSAGE);
  }
};

export const reviewReport = async (report: IReport) => {
  try {
    await Axios.put(`/output-report/${report.id}/review`, {
      type: report.type,
    });
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(e.response?.data.message);
    }
    throw new Error(UNEXPECTED_ERROR_MESSAGE);
  }
};

export const deleteIrsTrade = async (id: number) => {
  try {
    const response = await Axios.delete(`/irs-trade/${id}`);
    return response.data;
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(e.response?.data.message);
    }
    throw new Error(UNEXPECTED_ERROR_MESSAGE);
  }
};

export const getOrganizationIrsTrades = async (
  organizationId: number,
  query?: IGetOrganizationIrsTradesQuery,
): Promise<IIRSTradeAndCount> => {
  try {
    const response = await Axios.get(
      `/organization/${organizationId}/irs-trade`,
      {
        params: query,
      },
    );
    return response.data;
  } catch (e: unknown) {
    if (e instanceof AxiosError && e.response?.data.message) {
      throw new Error(e.response?.data.message);
    }
    throw new Error(UNEXPECTED_ERROR_MESSAGE);
  }
};
interface FXTradesApiQuery {
  entriesFrom?: Date;
  entriesTo?: Date;
  counterparty?: string;
  offset?: number;
  limit?: number;
  option: string;
}

export const getFxTrades = async (
  organizationId: number,
  query: FXTradesApiQuery,
): Promise<IFxTrade[]> => {
  try {
    const response = await Axios.get(
      `/organization/${organizationId}/fx-trade`,
      { params: query },
    );
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError && error.response?.data.message)
      throw new Error(error.response?.data.message);
    else if (error instanceof AxiosError) throw new Error(error.message);

    throw new Error(UNEXPECTED_ERROR_MESSAGE);
  }
};

export const getIrsTradeById = async (
  organizationId: number,
  tradeId: number,
): Promise<IIRSTrade> => {
  try {
    const response = await Axios.get(
      `/organization/${organizationId}/irs-trade/${tradeId}`,
    );
    return response.data;
  } catch (e: unknown) {
    if (e instanceof AxiosError && e.response?.data.message) {
      throw new Error(e.response?.data.message);
    }
    throw new Error(UNEXPECTED_ERROR_MESSAGE);
  }
};

export const getInputsFromOutput = async (
  outputId: number,
  isIrsJournal: boolean,
): Promise<IInputReportSummary[]> => {
  try {
    const queryParams = isIrsJournal ? '?isIRSJournal=true' : '';
    const response = await Axios.get(
      `/output-report/${outputId}/inputs${queryParams}`,
    );
    return response.data;
  } catch (e: unknown) {
    if (e instanceof AxiosError && e.response?.data.message) {
      throw new Error(e.response?.data.message);
    }
    throw new Error(UNEXPECTED_ERROR_MESSAGE);
  }
};
