import HotTable from '@handsontable/react';
import { useEffect, useState } from 'react';
import { useLoadWorkbook } from '../../../hooks/useLoadWorkbook';
import { insertEmptyRowAfter, parseRichText } from '../common/utils';
import { fxMtmJournalHeaders } from './FxMtmJournalHeaders';
import { FxMtmJournalReportSheetNames } from './ViewFxMtmJournalReportTable';
import { useLoadBasicSheet } from '../../../hooks/useLoadBasicSheet';
import {
  fxMtmJournalColumnConfig,
  fxMtmJournalMergeCells,
} from './fxMtmJournalConfig';
import { getIrsJournalCellProperties } from '../irs-mtm-journal/utils/getIrsJournalCellProperties';

export const FxMtmJournalStandardReportTable = ({
  report,
}: {
  report: Blob;
}) => {
  const { workbook } = useLoadWorkbook(
    report,
    'Error loading FX MTM Journal Report',
  );
  const { sheet: originalSheet } = useLoadBasicSheet(
    workbook,
    FxMtmJournalReportSheetNames.FX_MTM_JOURNAL,
  );
  const [sheet, setSheet] = useState<any[][]>([]);

  useEffect(() => {
    if (!originalSheet) return;
    const parsedSheet = parseRichText(originalSheet);
    const updatedSheet = insertEmptyRowAfter(parsedSheet, '-');
    setSheet(addFooterToSheet(updatedSheet));
  }, [originalSheet]);

  const addFooterToSheet = (sheet: any[][]) => {
    const noFooterPresent = sheet[sheet.length - 2][0] === '-';
    if (noFooterPresent) {
      sheet.push([
        'This report is confidential and is not to be copied, redistributed or disclosed in whole or in part without written consent from the System Vendor. Fair Values are powered by Hedgebook.',
      ]);
      sheet.push([null]);
    }
    return sheet;
  };

  return (
    <div className="flex justify-center">
      {sheet.length > 0 && (
        <HotTable
          readOnly
          key="fx-mtm-journal-report"
          data={sheet}
          rowHeaders={false}
          colHeaders={false}
          width={1450}
          height="auto"
          licenseKey="non-commercial-and-evaluation"
          rowHeights={35}
          manualColumnResize
          manualRowResize
          wordWrap
          allowEmpty={false}
          columns={fxMtmJournalColumnConfig}
          mergeCells={fxMtmJournalMergeCells(sheet)}
          cells={(row, col) =>
            getIrsJournalCellProperties(sheet, fxMtmJournalHeaders, row, col)
          }
        />
      )}
    </div>
  );
};
